// export var url = "http://driveandscan.de"; // -- old staging URL

export var url = "https://driveandscan.de"; // -- New staging URL
// export var url = "https://drivenscan.com";  // -- Production URL
// export var url = "https://backend.drivenscan.live"; // -- Backend Live
// export var url = "https://backend.drivenscan.ae"; // -- Backend AE
// export var url = "https://stg-backend.drivenscan.live"; // -- Backend Live Staging
// export var url = "https://newcom.drivenscan.ae";
// export var url = "https://newcom.drivenscan.ae";
// export var driveinURL = "https://dns-newtech-backend.driveandscan.de";  // -- Drive in API staging URL
export var driveinURL = "https://appointments.drivenscan.ch"; // -- Drive in API Production URL

var offlineurl = "/offlineApi";

export const configuration1 = {
  resultTxt: offlineurl + "/getVinRecords.json",
  getQRList: offlineurl + "/getQrList.json",
  imagePath: offlineurl + "/scanImages/",
  getOrders: offlineurl + "/api_v2/getOrders.php",
  GetPartDetails: offlineurl + "/api_v2/GetPartDetails.php",
  login: offlineurl + "/api_v2/loginCheck.php",
  getImages: offlineurl + "/api_v2/getImages.php",
  UpdateDentDetails: offlineurl + "/api_v2/UpdateDentDetails.php",
  Update_ResultTxt: offlineurl + "/api_v2/Update_ResultTxt.php",
  PostCustomerInfo: offlineurl + "/api_v2/PostCustomerInfo.php",
  getCustomerInfo: offlineurl + "/api_v2/getCustomerInfo.php",
  reportImage: offlineurl + "/scanImages/",
  uploadExtraImages: offlineurl + "/api_v2/uploadExtraImages.php",
  getExtraImages: offlineurl + "/api_v2/getExtraImages.php",
  deleteCommon: offlineurl + "/api_v2/delete-common.php",
  getDitInfo: offlineurl + "/api_v2/getDitInfo.php",
  getResultCH: offlineurl + "/api_v2/getResultCH.php",
  getResultSL: offlineurl + "/api_v2/getResultSL.php",
  getResultDat: offlineurl + "/api_v2/getResultDat.php",
  Update_ResultSL: offlineurl + "/api_v2/Update_ResultSL.php",
  GetResultSLChecked: offlineurl + "/api_v2/GetResultSLChecked.php",
  PostExtraImageDents: offlineurl + "/api_v2/PostExtraImageDents.php",
  getExtraImageDents: offlineurl + "/api_v2/getExtraImageDents.php",
  PostEditorChanges: offlineurl + "/api_v2/PostEditorChanges.php",
  getClients: offlineurl + "/api_v2/getClients.php",
  PostClients: offlineurl + "/api_v2/PostClients.php",
  getInsurenceClients: offlineurl + "/api_v2/JSON_API/getInsurenceClients.php",
  getData: offlineurl + "/api_v2/JSON_API/getData.php",
};

export const configuration = {
  apiBaseUrl: "http://localhost:9001/api/v1/",
  setLightStatus: url + "/api_v2/postAppInterfaceLightsStatus.php",
  postAppInterfaceColorStatus: url + "/api_v2/postAppInterfaceColor.php",
  resultTxt: url + "/api_v2/getVinRecords.php",
  validateAppInterface: url + "/api_v2/checkAppInterfaceConsoleAccess.php",
  getQRList: url + "/api_v2/getQrList.php",
  postSingleVINRecord: url + "/api_v2/scanlist/PostSingleVINRecord.php",
  getScanList: url + "/api_v2/scanlist/getScanList.php",
  getBase64: url + "/api_v2/imageToBase64.php",
  updateBase64: url + "/api_v2/replacePartImage.php",
  newDashboardImage: url + "/api_v2/newDashboardImages.php",
  getAppInterfaceStatus: url + "/api_v2/getAppInterfaceStatuses.php",
  resetInterfaceStatus: url + "/api_v2/resetAppInterfaceStatuses.php",
  postAppInterfaceStatus: url + "/api_v2/postAppInterfaceStatuses.php",
  imagePath: url,
  getOrders: url + "/api_v2/getOrders.php",
  getSearchedVinRecords: url + "/api_v2/searchVinRecords.php",
  GetPartDetails: url + "/api_v2/GetPartDetails.php",
  login: url + "/api_v2/loginCheck.php",
  getImages: url + "/api_v2/getImages.php",
  UpdateDentDetails: url + "/api_v2/UpdateDentDetails.php",
  Update_ResultTxt: url + "/api_v2/Update_ResultTxt.php",
  PostCustomerInfo: url + "/api_v2/PostCustomerInfo.php",
  getCustomerInfo: url + "/api_v2/getCustomerInfo.php",
  reportImage: url + "/scanImages/",
  uploadExtraImages: url + "/api_v2/uploadExtraImages.php",
  getExtraImages: url + "/api_v2/getExtraImages.php",
  deleteCommon: url + "/api_v2/delete-common.php",
  deleteImage: url + "/api_v2/delete-image.php",
  deleteVin: url + "/api_v2/delete-vin.php",
  getDitInfo: url + "/api_v2/getDitInfo.php",
  getResultCH: url + "/api_v2/getResultCH.php",
  getResultSL: url + "/api_v2/getResultSL.php",
  getResultDat: url + "/api_v2/getResultDat.php",
  Update_ResultSL: url + "/api_v2/Update_ResultSL.php",

  Rotate_image: url + "/api_v2/rotateUploadImage.php",

  GetResultSLChecked: url + "/api_v2/GetResultSLChecked.php",
  PostExtraImageDents: url + "/api_v2/PostExtraImageDents.php",
  getExtraImageDents: url + "/api_v2/getExtraImageDents.php",
  PostEditorChanges: url + "/api_v2/PostEditorChanges.php",
  getClients: url + "/api_v2/getClients.php",
  PostClients: url + "/api_v2/PostClients.php",
  getInsurenceClients: url + "/api_v2/JSON_API/getInsurenceClients.php",
  getData: url + "/api_v2/JSON_API/getData.php",

  postFallbackDetails: url + "/api_v2/app-profile.php",
  getFallbackDetails: url + "/api_v2/GetFallbackDetails.php",

  update360ImageLink: url + "/api_v2/update360ImageLink.php",
  get360ImageLink: url + "/api_v2/get360ImageLink.php",

  getFallbackLogoPath: url + "/scanImages/",

  uploadExtraScanListRecords:
    url + "/api_v2/scanlist/uploadExtraScanListRecordImages.php",

  getDashboardStatistics: url + "/api_v2/getDashboardStatistics.php",
  generatePDF: url + "/api_v2/reports/generatePDF.php",
  renameVIN: url + "/api_v2/rename-vin.php",
  createEmptyVin: url + "/api_v2/CreateEmptyVin.php",
  createStickyNote: url + "/api_v2/notes/createStickyNote.php",
  getSingleStickyNote: url + "/api_v2/notes/getSingleStickyNote.php",
  getAllStickyNotes: url + "/api_v2/notes/getAllStickyNotes.php",
  updateStickyNote: url + "/api_v2/notes/updateStickyNote.php",
  deleteStickyNote: url + "/api_v2/notes/deleteStickyNote.php",
  // http://driveandscan.de/api_v2/reports/generatePDF.php,
  getVinRecordsPaginate: url + "/api_v2/getVinRecordsPaginate.php",
  getQRListPaginate: url + "/api_v2/getQrListPaginate.php",
  searchGetVinRecords: url + "/api_v2/searchGetVinRecords.php",
  SearchQRDetails: url + "/api_v2/SearchQRDetails.php",
  updateQrList: url + "/api_v2/updateQrList.php",
  getSingleQRDetails: url + "/api_v2/getSingleQRDetails.php",
  getAllLocation: url + "/api_v2/getAllLocation.php",
  getLocation: url + "/api_v2/extadapter/get-drivein-location.php",
  updateQRInfo: url + "/api_v2/updateQrInfo.php",
  restoreJsonBackup: url + "/api_v2/restoreJsonBackup.php",
  vinExistsOrNot: url + "/api_v2/vinExistsOrNot.php",
  generateSwissDATReport: url + "/api_v2/reports/generateSwissDATReport.php",
  datReportCheck: url + "/api_v2/datReportCheck.php",
  getLogMessage: url + "/api_v2/getLogMessage.php",
  uploadExtraImagesNew: url + "/api_v2/uploadExtraImagesNew.php",
  extraImageToBase64: url + "/api_v2/extraImageToBase64.php",
  downloadExtraImage: url + "/api_v2/downloadExtraImage.php",
  uploadExtraPartImage: url + "/api_v2/uploadExtraPartImage.php",
  deleteExtraPartImage: url + "/api_v2/deleteExtraPartImage.php",
  getExtraPartImage: url + "/api_v2/getExtraPartImage.php",
  editExtraPartImage: url + "/api_v2/editExtraPartImage.php",
  rotateExtraPartImage: url + "/api_v2/rotateExtraPartImage.php",
  extraPartImageToBase64: url + "/api_v2/extraPartImageToBase64.php",
  authorizernamestore: url + "/api_v2/storeAuthorizedName.php",
  getContractor: url + "/api_v2/getContractor.php",
  allExtraImagePDF: url + "/api_v2/reports/allExtraImagePDF.php",
  allPartImagePDF: url + "/api_v2/reports/allPartImagePDF.php",
  downloadReports: url + "/api_v2/reports/downloadReports.php",
  generateFullReport: url + "/api_v2/reports/generateFullReport.php",
  getInsuranceEmails: url + "/api_v2/getInsuranceEmails.php",
  createDuplicateVin: url + "/api_v2/createDuplicateVin.php",
  getDuplicateVins: url + "/api_v2/getDuplicateVins.php",
  generatePDFAllVinQr: url + "/api_v2/reports/generatePDFAllVinQr.php",
  excelRead: url + "/api_v2/excelRead.php",
  base64ToReport: url + "/api_v2/reports/base64ToReport.php",
  predamageFlag: url + "/api_v2/predamageFlag.php",
  automated_scripts: url + "/api_v2/automated-scripts/generatePDF.php",
  automated_scripts_PDFDAT:
    url + "/api_v2/automated-scripts/generatePDFDAT.php",
  getScanImages: url + "/api_v2/getScanImages.php",
  getGraphStatistics: url + "/api_v2/getGraphStatistics.php",
  createUser: url + "/api_v2/User/createUser.php",
  getTemplates: url + "/api_v2/User/getTemplates.php",
  getMenuUser: url + "/api_v2/User/getMenuUser.php",
  getUser: url + "/api_v2/User/getUser.php",
  deleteUser: url + "/api_v2/User/deleteUser.php",
  activeDeactiveUser: url + "/api_v2/User/activeDeactiveUser.php",
  updateUser: url + "/api_v2/User/updateUser.php ",
  changePassword: url + "/api_v2/User/changePassword.php ",
  getVIPdf: url + "/api_v2/get_vi_pdf.php",
  datFastTrack: url + "/api_v2/dat/fasttrack_ai.php",
  carfax: url + "/api_v2/carfax/carfax.php",
  getCarfaxDataJSON: url + "/api_v2/carfax/getCarfaxDataJSON.php",
  tireSideWellAnalysis: url + "/api_v2/tiresidewallAnalysis.php",
  externalEmailSend: url + "/api_v2/external-email-send.php",
  storeLog: url + "/api_v2/store-log.php",
  getTotalDiamm: url + "/api_v2/gettotaldiammofallpart.php",
  getImageToBase64: url + "/api_v2/image-to-base64.php",
  getAuthorizedStatus: url + "/api_v2/get-autorized-status.php",
  datMyClaim: url + "/api_v2/dat/my-cliam.php",

  // Audatex URL
  taskService: url + "/api_v2/taskService.php",
  audatex: url + "/api_v2/extadapter/audatex.php",
  calculationService: url + "/api_v2/calculationService.php",

  // VI Related API's
  generateVIToken: url + "/api_v2/visualIntelligenceImage.php",
  visualIntelligenceImage: url + "/api_v2/visualIntelligenceImagePart2.php",
  savePDF: url + "/api_v2/VIPdf.php",

  generatePDFWithEmail: url + "/api_v2/automated-scripts/generatePDF.php",
  storeAuthorizedName: url + "/api_v2/storeAuthorizedName.php",

  internalStatus: driveinURL + "/api/v1/extadapter/internal-status",
  updateInsuranceEmails: url + "/api_v2/update-insurance-emails.php",

  // Language
  addUpdateLanguage: url + "/api_v2/language/add-update.php",
  getLanguageList: url + "/api_v2/language/list.php",

  // Third Party
  ec2ThirdParty: url + "/api_v2/extadapter/ec2.php",
  soleraThirdParty: url + "/api_v2/extadapter/audatex.php",
  kvThirdParty: url + "/api_v2/extadapter/audatex-report.php",
  postThirdPartyCredential: url + "/api_v2/third_party_credential.php",
  getThirdPartyCredential: url + "/api_v2/get_third_party_credential.php",

  //E-mail Functionality
  add_email: url + "/api_v2/email/add_email.php",
  getEmailData: url + "/api_v2/email/list_email.php",
  deleteEmail: url + "/api_v2/email/delete_email.php",
  editEmail: url + "/api_v2/email/edit_email.php",
  changeStatus: url + "/api_v2/email/change_status.php",

  //Scan Email Functionality
  add_ScanEmail: url + "/api_v2/email/add_user_emails.php",
  getScanEmailData: url + "/api_v2/email/list_user_email.php",
  deleteScanEmail: url + "/api_v2/email/delete_user_email.php",
  editScanEmail: url + "/api_v2/email/edit_user_email.php",
  changeStatusScanEmail: url + "/api_v2/email/user_email_change_status.php",

  //Authorizer Name
  getAuthorizeName: url + "/api_v2/authorize_name/list.php",
  addAuthorizeName: url + "/api_v2/authorize_name/add.php",
  editAuthorizeName: url + "/api_v2/authorize_name/edit.php",
  deleteAuthorizeName: url + "/api_v2/authorize_name/delete.php",
  statusAuthorizeName: url + "/api_v2/authorize_name/change_status.php",
  CalculateExpert: url + "/api_v2/dat/calculate.php",

  //openDAT URL
  openDAT: "https://www.dat.de/myClaim/inbox.jsp?",
  openDATGold: "https://gold.dat.de/myClaim/inbox.jsp?",

  getVersionUrl: url,

  //Version Api
  getVersion: url + "/api_v2/version/get.php",
  addVersion: url + "/api_v2/version/add.php",

  // internal API
  getDriveInRegion: driveinURL + "/api/v1/extadapter/get/region",

  americanReport: url + "/api_v2/reports/americanReport.php",

  //duplicate vin
  duplicateVin: url + "/api_v2/duplicate_vin.php",

  //mobile user
  createMobileUser: url + "/api_v2/mobileuser/create.php",
  deleteMobileUser: url + "/api_v2/mobileuser/delete.php",
  listMobileUser: url + "/api_v2/mobileuser/list.php",
  changeMobilePassword: url + "/api_v2/mobileuser/change-password.php",

  //insurance client management
  createInsuranceClient: url + "/api_v2/insurance_client/create.php",
  updateInsuranceClient: url + "/api_v2/insurance_client/create.php",
  listInsuranceClient: url + "/api_v2/insurance_client/list.php",
  deleteInsuranceClient: url + "/api_v2/insurance_client/delete.php",
  setInsuranceClientOrder: url + "/api_v2/insurance_client/order_by.php",

  // dent marking
  dentMarking: url + "/api_v2/dent_marking.php",
  getMarkingStatus: url + "/api_v2/get-marking-status.php",

  //reset Authorize
  resetAuthorized: url + "/api_v2/reset_authorized.php",

  //chat room
  getChatVin: url + "/api_v2/chat/get_vin.php",
  createNote: url + "/api_v2/chat/create.php",
  updateChat: url + "/api_v2/chat/update.php",
  downloadFiles: url + "/api_v2/chat/download.php",
  getSingleChat: url + "/api_v2/chat/single_chat.php",
  getAllChat: url + "/api_v2/chat/get_all.php",

  //upload scan image
  uploadScanImage: url + "/api_v2/upload-scanimage.php",

  //Third Party
  updateThirdPartyicon: url + "/api_v2/third_party_icon/add_edit_icon.php",
  getThirdPartyicon: url + "/api_v2/third_party_icon/list_icon.php",

  // .live 24-230315 Vi Json api
  getViJson : url + "/api_v2/vi_json.php",
  aiDamageFill : url + "/api_v2/ai_damage_fill.php",

  // damages vin
  getDamagesVin: url + "/api_v2/damages_vin.php",
};

export const configuration2 = {
  mfaLogin: url + "/api_v2/login.php",
  mfaChangePassword: url + "/api_v2/User/change-password.php",
  mfaEmailOtpSend: url + "/api_v2/email-otp-send.php",
  mfaEmailOtpVerify: url + "/api_v2/email-otp-verify.php",
  mfaVarify: url + "/api_v2/mfa-verify.php",
  mfaForgotPassword: url + "/api_v2/forgot-password.php",
  mfaGetUser: url + "/api_v2/User/get-user.php",
  getRoleList: url + "/api_v2/User/list-role.php",
  newCreateUser: url + "/api_v2/User/create-user.php",
  userList: url + "/api_v2/User/list-user.php",
  newUserDelete: url + "/api_v2/User/delete-user.php",
  changeUserStatus: url + "/api_v2/User/status-user.php",
  newUpdateUser: url + "/api_v2/User/update-user.php  ",
  checkAuthorization: url + "/api_v2/check-authentication.php",
  changeOrderStatus : url + "/api_v2/order-confirm.php",
};
