const lang = localStorage.getItem("lang");
const initialState = {
  lang: lang,
  staticUrl: "",
  jsonData: [],
  toggleReportDamageImage: true,
  allPartnerId: [],
  selectedPartnerId: [],
  reportAuthorized: {},
  insuranceClient: [],
};
const loginState = {
  loginDetails: {},
}
export const countReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_lANG":
      return { ...state, lang: action.payload };
    case "SET_STATIC_URL":
      return { ...state, staticUrl: action.payload };
    case "SET_JSON_DATA":
      return { ...state, jsonData: action.payload };
    case "TOGGLE_DAMAGE_IMAGES":
      return { ...state, toggleReportDamageImage: action.payload };
    case "GET_ALL_PARTNERID":
      return { ...state, allPartnerId: action.payload };
    case "GET_SELECTED_PARTNERID":
      return { ...state, selectedPartnerId: action.payload };
    case "REPORT_AUTHORIZED":
      return { ...state, reportAuthorized: action.payload };
    case "INSURANCE_CLIENT":
      return { ...state, insuranceClient: action.payload };
    default:
      return state;
  }
};

export const loginDetails = (state = loginState, action) => {
  switch (action.type) {
    case "LOGIN_DETAILS":
      return { ...state, loginDetails: action.payload };
    default:
      return state;
  }
};
