import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

class PrivateRoute extends Component {
  render() {
    const { children, ...rest } = this.props;
    const authToken = this.props.props.login.loginDetails && this.props.props.login.loginDetails.access_token; 
    const isAuthenticated = Boolean(authToken && authToken.trim() && authToken !== "undefined" && authToken !== undefined);
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }, // Save the attempted route for redirection after login
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (props) => {
  return {
    props: props,
  };
};

export default connect(mapStateToProps)(withRouter(PrivateRoute));
