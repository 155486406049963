import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "./views/Pages/Login/ChangePassword";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const LoginWithMfa = React.lazy(() =>
  import("./views/Pages/Login/LoginWithMfa")
);
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/Login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/Pages/Login/ResetPassword")
);
const CreatePassword = React.lazy(() =>
  import("./views/Pages/Login/CreatePassword")
);
const TwoFactorAuthentication = React.lazy(() =>
  import("./views/Pages/Login/TwoFactorAuthentication")
);
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div class="de-loader with-bg">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newToken =
      nextProps.props.login.loginDetails &&
      nextProps.props.login.loginDetails.access_token;
    if (String(newToken) !== String(prevState.authToken)) {
      return { authToken: newToken };
    } else {
      return null;
    }
  }
  render() {
    const { authToken } = this.state;
    const isAuthenticated = Boolean(
      authToken &&
      authToken.trim() &&
      authToken !== "undefined" &&
      authToken !== undefined
    );

    return (
      <React.Suspense fallback={loading()}>
        <Switch>
          {/* Routes for unauthenticated users */}
          {!isAuthenticated && (
            <>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={(props) => <LoginWithMfa {...props} />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path="/reset-password/:id"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/create-password"
                  render={(props) => <CreatePassword {...props} />}
                />
                <Route
                  exact
                  path="/two-factor-authentication"
                  render={(props) => <TwoFactorAuthentication {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  render={(props) => <Page404 {...props} />}
                />
                <Redirect from="*" to="/login" />
              </Switch>
            </>
          )}

          {/* Routes for authenticated users */}
          {isAuthenticated && (
            <>
              <Switch>
                <PrivateRoute>
                  <Route
                    path="/*"
                    name="Dashboard"
                    render={(props) => <DefaultLayout {...props} />}
                  />
                </PrivateRoute>
                <Route
                  exact
                  path="/404"
                  render={(props) => <Page404 {...props} />}
                />
              </Switch>
              {/* Redirect unauthenticated users */}
            </>
          )}
        </Switch>
      </React.Suspense>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    props: props,
  };
};

export default connect(mapStateToProps)(withRouter(App));
