import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default localStorage for web
import { countReducer, loginDetails } from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";

// Persist config for the loginDetails reducer
const persistLoginConfig = {
  key: "login", // Key for persist storage
  storage,      // Storage engine
};

// Persisted reducer for loginDetails
const persistedLoginReducer = persistReducer(persistLoginConfig, loginDetails);

// Combine all reducers
const rootReducer = combineReducers({
  countReducer, 
  login: persistedLoginReducer, 
});

// Create the Redux store with middleware
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)) // Add dev tools and thunk middleware
);

// Create the persistor for the store
const persistor = persistStore(store);

export { store, persistor };
